/* eslint react/no-unknown-property: 0 */

import { memo, useEffect, useRef } from 'react'

import { Canvas, Scene } from 'components/3DScene'

import { ProjectCanvasContainer } from './styled'

import ProjectDevice from './ProjectDevice'

let sharedVideoElement: HTMLVideoElement | null = null

const getSharedVideoElement = () => {
  if (!sharedVideoElement) {
    sharedVideoElement = document.createElement('video')
    sharedVideoElement.crossOrigin = 'anonymous'
    sharedVideoElement.loop = true
    sharedVideoElement.muted = true
    sharedVideoElement.controls = false
    sharedVideoElement.autoplay = true
    sharedVideoElement.playsInline = true
  }
  return sharedVideoElement
}

const ProjectCanvas = ({ currentProject, inView, getCurrentVideoLength }: IProjectCanvas) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const previousProjectRef = useRef<string | null>(null)

  useEffect(() => {
    videoRef.current = getSharedVideoElement()
  }, [])

  useEffect(() => {
    if (videoRef.current && currentProject.showcaseVideo) {
      previousProjectRef.current = currentProject.showcaseVideo

      videoRef.current.pause()
      videoRef.current.src = currentProject.showcaseVideo

      if (inView) {
        videoRef.current.play()
      }
    }
  }, [currentProject, inView])

  return (
    <ProjectCanvasContainer>
      <Canvas canvasProps={{ dpr: 1 }}>
        <ambientLight intensity={-0.8} />
        <spotLight position={[0, 10, 40]} intensity={0.5} />
        <Scene
          cameraProps={{ position: [0, 0.5, 45], near: 0.1, far: 100, zoom: 3 }}
          spotlightProps={{ position: [0, 20, 9], intensity: 9 }}
        />
        <ProjectDevice
          getCurrentVideoLength={getCurrentVideoLength}
          currentProject={currentProject}
          videoRef={videoRef}
          inView={inView}
        />
      </Canvas>
    </ProjectCanvasContainer>
  )
}

export default memo(ProjectCanvas)
