import { Box, styled } from '@mui/material'
import { preventForwardProps } from 'utils/styledUtils'

export const IndicatorBar = styled(Box)(({ theme }) => ({
  width: 0,
  height: '100%',
  borderRadius: theme.spacing(1),
  position: 'absolute',
  left: 0,
  top: 0,
  pointerEvents: 'none',
}))

export const IndicatorContainer = styled(
  'div',
  preventForwardProps(['backgroundColor']),
)<IIndicatorContainerProps>(({ theme, backgroundColor = null }) => ({
  backgroundColor: backgroundColor ?? theme.palette.surface['800'],
  flexGrow: 1,
  height: theme.spacing(0.5),
  borderRadius: theme.spacing(1),
  position: 'relative',
  cursor: 'pointer',
}))
