import { Typography } from '@mui/material'
import SocialMedia from '../SocialMedia'
import { ClientInfoContainer, Container } from './styled'
import { FC } from 'react'

const ClientInfo: FC<ITestimonial> = ({ testimonial }) => {
  return (
    <ClientInfoContainer>
      <Container>
        <Typography variant="h6" component="p">
          {testimonial.clientName}
        </Typography>
        <SocialMedia testimonial={testimonial} />
      </Container>

      <Typography variant="body2">{testimonial.clientPosition}</Typography>
    </ClientInfoContainer>
  )
}

export default ClientInfo
