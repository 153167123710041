import { Theme, useMediaQuery } from '@mui/material'
import { FC } from 'react'
import Avatar from '../Avatar'
import Buttons from '../Buttons'
import {
  ButtonsContainerMobile,
  ButtonsContainerTablet,
  PersonalInfoContainer,
} from '../Testimonial/styled'
import { Container } from './styled'

const Profile: FC<IProfile> = ({ testimonial }) => {
  const downSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const onlySm = useMediaQuery<Theme>((theme) => theme.breakpoints.only('sm'))

  return (
    <Container>
      {downSm && (
        <ButtonsContainerMobile>
          <Buttons leftSide />
        </ButtonsContainerMobile>
      )}

      <PersonalInfoContainer>
        <Avatar
          clientPhoto={testimonial.clientPhoto.fullSize}
          imageAltText={testimonial.clientPhotoAltText}
        />
        {onlySm && (
          <ButtonsContainerTablet>
            <Buttons leftSide />
            <Buttons />
          </ButtonsContainerTablet>
        )}
      </PersonalInfoContainer>

      {downSm && (
        <ButtonsContainerMobile>
          <Buttons />
        </ButtonsContainerMobile>
      )}
    </Container>
  )
}

export default Profile
