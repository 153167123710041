import { useCallback, useEffect, useMemo, useState } from 'react'
import { Theme, useMediaQuery } from '@mui/material'
import { Float } from '@react-three/drei'

import LaptopModel from 'public/assets/3dmodels/laptop/LaptopModel'
import MobileVerticalModel from 'public/assets/3dmodels/mobile/MobileVerticalModel'
import MobileHorizontalModel from 'public/assets/3dmodels/mobile/MobileHorizontalModel'
import TabletHorizontalModel from 'public/assets/3dmodels/tablet/TabletHorizontalModel'
import TabletVerticalModel from 'public/assets/3dmodels/tablet/TabletVerticalModel'
import { IScreenMedia } from 'public/assets/3dmodels/types'

import { deviceProps } from './constants'

const ProjectDevice = ({
  currentProject,
  videoRef,
  inView,
  getCurrentVideoLength,
}: IProjectDevice) => {
  const [isLoadingVideo, setIsLoadingVideo] = useState(false)

  const upSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))
  const upMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))
  const upLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))
  const upXl = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'))

  useEffect(() => {
    if (videoRef.current) {
      if (inView) {
        if (videoRef.current.src) {
          videoRef.current.play().catch()
        }
      } else {
        videoRef.current.pause()
      }
    }
  }, [inView, videoRef])

  useEffect(() => {
    if (videoRef && videoRef.current) {
      if (currentProject?.showcaseVideo) {
        setIsLoadingVideo(true)

        const video = videoRef.current

        const handleLoaded = () => {
          getCurrentVideoLength(video)
          setIsLoadingVideo(false)
        }

        const handlePlay = () => {
          setIsLoadingVideo(false)
        }

        video.removeEventListener('loadeddata', handleLoaded)
        video.removeEventListener('play', handlePlay)

        video.addEventListener('loadeddata', handleLoaded)
        video.addEventListener('play', handlePlay)

        return () => {
          video.removeEventListener('loadeddata', handleLoaded)
          video.removeEventListener('play', handlePlay)
        }
      } else {
        setIsLoadingVideo(false)
      }
    }
  }, [currentProject?.showcaseVideo, videoRef, getCurrentVideoLength])

  const screenMedia = useMemo(
    () =>
      ({
        type: currentProject?.showcaseVideo ? 'video' : 'image',
        src: currentProject?.showcaseVideo || currentProject?.showcaseImage?.fullSize,
        ref: videoRef,
        contentId: currentProject?.id + (currentProject?.showcaseVideo ? '-video' : '-image'),
      } as IScreenMedia),
    [
      videoRef,
      currentProject?.id,
      currentProject?.showcaseVideo,
      currentProject?.showcaseImage?.fullSize,
    ],
  )

  const getDeviceProps = useCallback(() => {
    const currentDeviceProps = deviceProps[currentProject.deviceType]

    if (upXl) return currentDeviceProps.xl
    if (upLg) return currentDeviceProps.lg
    if (upMd) return currentDeviceProps.md
    if (upSm) return currentDeviceProps.sm

    return currentDeviceProps.xs
  }, [upSm, upMd, upLg, upXl, currentProject])

  const getDevice = useCallback(() => {
    const { deviceType } = currentProject
    const deviceProps = getDeviceProps()

    switch (deviceType) {
      case 'desktop':
        return (
          <LaptopModel {...deviceProps} screenMedia={screenMedia} loadingContent={isLoadingVideo} />
        )
      case 'mobile_portrait':
        return (
          <MobileVerticalModel
            {...deviceProps}
            screenMedia={screenMedia}
            loadingContent={isLoadingVideo}
          />
        )
      case 'mobile_landscape':
        return (
          <MobileHorizontalModel
            {...deviceProps}
            screenMedia={screenMedia}
            loadingContent={isLoadingVideo}
          />
        )
      case 'tablet_landscape':
        return (
          <TabletHorizontalModel
            {...deviceProps}
            screenMedia={screenMedia}
            loadingContent={isLoadingVideo}
          />
        )
      case 'tablet_portrait':
        return (
          <TabletVerticalModel
            {...deviceProps}
            screenMedia={screenMedia}
            loadingContent={isLoadingVideo}
          />
        )
    }
  }, [getDeviceProps, currentProject, screenMedia, isLoadingVideo])

  return (
    <Float floatIntensity={0.2} rotationIntensity={0.2}>
      <group>{getDevice()}</group>
    </Float>
  )
}

export default ProjectDevice
