import { memo, useCallback, useMemo } from 'react'
import { useQuery } from '@baseapp-frontend/core'

import LoadingState from './LoadingState'
import Container from 'components/Container'

import TestimonialsApi from 'api/TestimonialsApi'

const CAROUSEL_DELAY = 50000 // ms

import { CarouselProvider } from 'components/TimedCarousel/context'
import TestimonialsCarousel from './TestimonialsCarousel'

const TestimonialsSection = () => {
  const { data, isFetching } = useQuery('testimonials', () => TestimonialsApi.getTestimonials())
  const testimonials = useMemo(() => data?.results || [], [data])

  const carouselDelay = useCallback(() => CAROUSEL_DELAY, [])

  return (
    <Container>
      <CarouselProvider slideCount={testimonials.length} delayForSlide={carouselDelay}>
        {isFetching ? <LoadingState /> : <TestimonialsCarousel testimonials={testimonials} />}
      </CarouselProvider>
    </Container>
  )
}

export default memo(TestimonialsSection)
