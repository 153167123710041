/* eslint react/no-unknown-property: 0 */

import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Theme, useMediaQuery } from '@mui/material'
import { useThree } from '@react-three/fiber'
import { Group, MathUtils, Vector3 } from 'three'

import LaptopModel from 'public/assets/3dmodels/laptop/LaptopModel'
import { IScreenMedia } from 'public/assets/3dmodels/types'

import { deviceProps, modelFinalStates } from './constants'

const Laptop = ({ inView }: { inView: boolean }) => {
  const modelRef = useRef<Group | null>(null)
  const screenRef = useRef<Group | null>(null)
  const lastTimeRef = useRef<number | null>(null)

  const { advance } = useThree()

  const targetPosition = useMemo(() => new Vector3(), [])

  const upSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))
  const upMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))
  const upLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))
  const upXl = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'))

  const screenMedia = useMemo(
    () =>
      ({
        type: 'image',
        src: '/assets/tsl-team-france.webp', // should be a marketing video
        contentId: 'tsl-team-france',
      } as IScreenMedia),
    [],
  )

  const getDeviceProps = useCallback(() => {
    if (upXl) return deviceProps.xl
    if (upLg) return deviceProps.lg
    if (upMd) return deviceProps.md
    if (upSm) return deviceProps.sm

    return deviceProps.xs
  }, [upSm, upMd, upLg, upXl])

  const finalStates = useMemo(() => {
    if (upXl) return modelFinalStates.xl
    if (upLg) return modelFinalStates.lg
    if (upMd) return modelFinalStates.md
    if (upSm) return modelFinalStates.sm

    return modelFinalStates.xs
  }, [upSm, upMd, upLg, upXl])

  const isFinalPosition = useCallback(() => {
    if (!modelRef.current) return false

    const { finalModelXPosition, finalModelYPosition, finalModelYRotation, finalModelScale } =
      finalStates

    return (
      Math.abs(modelRef.current.position.x - finalModelXPosition) < 0.01 &&
      Math.abs(modelRef.current.position.y - finalModelYPosition) < 0.01 &&
      Math.abs(modelRef.current.rotation.y - finalModelYRotation) < 0.01 &&
      Math.abs(modelRef.current.scale.x - finalModelScale) < 0.01
    )
  }, [finalStates])

  useEffect(() => {
    if (!inView) return

    let frameId: number | null = null
    let frameCounter = 0
    const delayFrames = 90
    let isActive = true

    const animationLoop = (time: number) => {
      if (!isActive || !inView || !modelRef.current || !screenRef.current) return

      if (isFinalPosition()) {
        advance(time)
        return
      }

      advance(time) // rendering frame

      // delaying animation for 90 frames
      if (frameCounter < delayFrames) {
        frameCounter++
        frameId = requestAnimationFrame(animationLoop)
        return
      }

      const lastTime = lastTimeRef.current ?? time
      const delta = Math.min((time - lastTime) / 1000, 0.1)

      lastTimeRef.current = time

      const {
        finalModelXPosition,
        finalModelYPosition,
        finalModelYRotation,
        finalModelScale,
        finalScreenXRotation,
      } = finalStates
      const model = modelRef.current
      const screen = screenRef.current

      targetPosition.set(
        MathUtils.damp(model.position.x, finalModelXPosition, 1, delta),
        MathUtils.damp(model.position.y, finalModelYPosition, 1, delta),
        model.position.z,
      )

      model.rotation.y = MathUtils.damp(model.rotation.y, finalModelYRotation, 1, delta)
      model.position.copy(targetPosition)
      model.scale.setScalar(MathUtils.damp(model.scale.x, finalModelScale, 1, delta))
      screen.rotation.x = MathUtils.damp(screen.rotation.x, finalScreenXRotation, 1, delta)

      frameId = requestAnimationFrame(animationLoop)
    }

    lastTimeRef.current = null
    frameId = requestAnimationFrame(animationLoop)

    return () => {
      isActive = false
      if (frameId) {
        cancelAnimationFrame(frameId)
        frameId = null
      }
      lastTimeRef.current = null
    }
  }, [inView, advance, isFinalPosition, finalStates, targetPosition])

  return (
    <group>
      <LaptopModel
        {...getDeviceProps()}
        screenMedia={screenMedia}
        screenProps={{
          rotation: [1.638, 0, 0],
        }}
        setRef={(ref) => {
          modelRef.current = ref
        }}
        setScreenRef={(ref) => {
          screenRef.current = ref
        }}
      />
    </group>
  )
}

export default Laptop
