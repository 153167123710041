import { useEffect } from 'react'
import { Theme, useMediaQuery } from '@mui/material'
import { useInView } from 'framer-motion'
import Vertical from './Vertical'
import VerticalsIndicators from './VerticalsIndicators'
import Carousel from 'components/TimedCarousel'
import { VerticalsIndicatorsContainer } from './VerticalsIndicators/styled'
import { useCarouselState } from 'components/TimedCarousel/context'

export const VerticalsCarousel = ({
  containerRef,
  verticals,
}: {
  containerRef: React.RefObject<HTMLDivElement>
  verticals: IVertical[]
}) => {
  const isLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))

  const { currentSlide, getCurrentTimePercentage, setSlide, pauseTimer, resumeTimer } =
    useCarouselState()

  const inView = useInView(containerRef, { amount: 0.5 })

  useEffect(() => {
    if (inView) {
      resumeTimer()
    } else {
      pauseTimer()
    }
  }, [inView, pauseTimer, resumeTimer])

  return (
    <div ref={containerRef}>
      {isLg && (
        <VerticalsIndicatorsContainer>
          <VerticalsIndicators
            inView={inView}
            verticals={verticals}
            currentSlide={currentSlide}
            getCurrentTimePercentage={getCurrentTimePercentage}
            setSlide={setSlide}
          />
        </VerticalsIndicatorsContainer>
      )}

      <Carousel index={currentSlide}>
        {verticals.map((vertical: IVertical) => (
          <Vertical inView={inView} key={vertical.slug} vertical={vertical} />
        ))}
      </Carousel>
    </div>
  )
}
