import { Box, styled } from '@mui/material'

export const Container = styled(Box)(({ theme }) => ({
  display: 'block',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(4),
  },
}))
