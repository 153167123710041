import {
  ButtonsContainerDesktop,
  ContentContainer,
  TestimonialContainer,
  TestimonialWrapper,
} from './styled'
import { FC, memo, useCallback, useState } from 'react'
import Profile from '../Profile'
import Content from '../Content'
import Buttons from '../Buttons'
import VideoModal from '../VideoModal'
import { useCarouselState } from 'components/TimedCarousel/context'
import Indicators from 'components/TimedCarousel/Indicators'

const Testimonial: FC<ITestimonialProps> = ({ index, inView, testimonial }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const { setSlide, pauseTimer, resumeTimer, slideCount, currentSlide, getCurrentTimePercentage } =
    useCarouselState()

  const handleOpenModal = useCallback(() => {
    setModalOpen(true)
    pauseTimer()
  }, [pauseTimer])

  const handleCloseModal = useCallback(
    (ref?: React.RefObject<HTMLVideoElement>) => {
      if (ref && ref.current) {
        ref.current.pause()
        ref.current.currentTime = 0
        ref.current.src = ''
      }
      setModalOpen(false)
      resumeTimer()
    },
    [resumeTimer],
  )

  return (
    <TestimonialContainer>
      <ButtonsContainerDesktop sx={{ marginRight: 4 }}>
        <Buttons leftSide />
      </ButtonsContainerDesktop>

      <TestimonialWrapper>
        <ContentContainer>
          <Profile testimonial={testimonial} />
          <Content testimonial={testimonial} handleOpenModal={handleOpenModal} />
        </ContentContainer>
        <Indicators
          inView={inView && index === currentSlide}
          count={slideCount}
          currentSlide={currentSlide}
          getCurrentTimePercentage={getCurrentTimePercentage}
          setSlide={setSlide}
        />
      </TestimonialWrapper>

      <ButtonsContainerDesktop sx={{ marginLeft: 'auto' }}>
        <Buttons />
      </ButtonsContainerDesktop>

      <VideoModal
        testimonial={testimonial}
        handleCloseModal={handleCloseModal}
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
      />
    </TestimonialContainer>
  )
}

export default memo(Testimonial)
