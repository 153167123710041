import { useState, useCallback, useEffect, useRef } from 'react'

interface ITimedCarouselHook {
  currentSlide: number
  nextSlide: () => void
  prevSlide: () => void
  setSlide: (slide: number) => void
  pauseTimer: () => void
  resumeTimer: () => void
  getCurrentTime: () => number
  getCurrentTimePercentage: () => number
}

export default function useTimedCarousel(
  slideCount = 0,
  delayForSlide: (slide: number) => number,
  initialSlideIndex = 0,
): ITimedCarouselHook {
  const [currentSlide, setCurrentSlide] = useState(initialSlideIndex)
  const [isActive, setIsActive] = useState(true)
  const [currentDelay, setCurrentDelay] = useState(delayForSlide(0))

  const currentTime = useRef(0)
  const lastUpdateTime = useRef(0)
  const animationRef = useRef<number | null>(null)
  const isRunning = useRef(false)

  const resetTimer = useCallback(() => {
    currentTime.current = 0
    lastUpdateTime.current = performance.now()
  }, [])

  const nextSlide = useCallback(() => {
    setCurrentSlide((prevState) => {
      const next = prevState + 1
      return next < slideCount ? next : 0
    })
  }, [slideCount])

  const prevSlide = useCallback(() => {
    setCurrentSlide((prevState) => {
      const prev = prevState - 1
      return prev >= 0 ? prev : slideCount - 1
    })
  }, [slideCount])

  const setSlide = useCallback(
    (slide: number) => {
      setCurrentSlide(slide)
      resetTimer()
    },
    [setCurrentSlide, resetTimer],
  )

  const handleNextSlide = useCallback(() => {
    resetTimer()
    nextSlide()
  }, [nextSlide, resetTimer])

  const handlePrevSlide = useCallback(() => {
    resetTimer()
    prevSlide()
  }, [prevSlide, resetTimer])

  const pauseTimer = useCallback(() => {
    setIsActive(false)
  }, [])

  const resumeTimer = useCallback(() => {
    setIsActive(true)
    lastUpdateTime.current = performance.now()
  }, [])

  const animate = useCallback((timestamp: number) => {
    if (!isRunning.current) return
    
    const deltaTime = timestamp - lastUpdateTime.current
    lastUpdateTime.current = timestamp
    
    const _delay = delayForSlide(currentSlide)
    
    currentTime.current += deltaTime
    
    if (currentTime.current >= _delay) {
      handleNextSlide()
    }
    
    animationRef.current = requestAnimationFrame(animate)
  }, [currentSlide, delayForSlide, handleNextSlide])

  useEffect(() => {
    const _delay = delayForSlide(currentSlide)
    setCurrentDelay(_delay)
    
    if (isActive && slideCount > 1) {
      if (!isRunning.current) {
        isRunning.current = true
        lastUpdateTime.current = performance.now()
        animationRef.current = requestAnimationFrame(animate)
      }
    } else {
      isRunning.current = false
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
        animationRef.current = null
      }
    }
    
    return () => {
      isRunning.current = false
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
        animationRef.current = null
      }
    }
  }, [isActive, slideCount, currentSlide, delayForSlide, animate])

  const getCurrentTime = useCallback(() => {
    return currentTime.current
  }, [])

  const getCurrentTimePercentage = useCallback(() => {
    return (currentTime.current / currentDelay) * 100
  }, [currentDelay])

  return {
    currentSlide,
    nextSlide: handleNextSlide,
    prevSlide: handlePrevSlide,
    setSlide,
    pauseTimer,
    resumeTimer,
    getCurrentTime,
    getCurrentTimePercentage,
  }
}
