import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { motion, useInView } from 'framer-motion'

import Project from './Project'
import Loading from './Loading'
import ProjectCanvas from './ProjectCanvas'
import Container from 'components/Container'
import Indicators from 'components/TimedCarousel/Indicators'
import useTimedCarousel from 'components/TimedCarousel/hooks/useTimedCarousel'
import Carousel from 'components/TimedCarousel'

import { IndicatorsContainer, ProjectsContainer } from './styled'

import { useProjects } from 'hooks/projects/useProjects'
import { projectsCarouselId } from 'shared/constants'

const canvasAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 2 },
}

function Projects() {
  const containerRef = useRef(null)
  const [currentVideoLength, setCurrentVideoLength] = useState(10000)

  const { projects: data, isLoadingProjects } = useProjects({
    apiParams: { expand: 'stats', is_featured: true },
  })

  const inView = useInView(containerRef, { amount: 0.5 })

  const projects = useMemo(() => data || [], [data])

  const getCurrentVideoLength = useCallback((videoElement: HTMLVideoElement) => {
    setCurrentVideoLength(videoElement.duration * 1000 || 10000)
  }, [])

  const carouselDelay = useCallback(() => currentVideoLength, [currentVideoLength])

  const {
    currentSlide,
    getCurrentTimePercentage,
    prevSlide,
    nextSlide,
    setSlide,
    resumeTimer,
    pauseTimer,
  } = useTimedCarousel(projects.length, carouselDelay)

  useEffect(() => {
    if (inView) {
      resumeTimer()
    } else {
      pauseTimer()
    }
  }, [inView, resumeTimer, pauseTimer])

  const currentProject = useMemo(() => projects[currentSlide], [projects, currentSlide])

  const controlsProps = useMemo(
    () => ({
      leftDisabled: currentSlide === 0,
      rightDisabled: currentSlide === projects.length - 1,
      prevSlide,
      nextSlide,
    }),
    [currentSlide, projects.length, prevSlide, nextSlide],
  )

  return (
    <div style={{ position: 'relative', width: '100%' }} id={projectsCarouselId}>
      <Container>
        <ProjectsContainer ref={containerRef}>
          {isLoadingProjects || !projects.length ? (
            <Loading />
          ) : (
            <>
              <IndicatorsContainer>
                <Indicators
                  inView={inView}
                  count={projects.length}
                  currentSlide={currentSlide}
                  getCurrentTimePercentage={getCurrentTimePercentage}
                  setSlide={setSlide}
                />
              </IndicatorsContainer>
              <Carousel index={currentSlide}>
                {projects.map((project, index) => {
                  const shouldRender =
                    index === currentSlide ||
                    index === currentSlide - 1 ||
                    index === currentSlide + 1

                  return shouldRender ? (
                    <Project key={project.id} project={project} controlsProps={controlsProps} />
                  ) : (
                    <div key={project.id} />
                  )
                })}
              </Carousel>
            </>
          )}
          {currentProject ? (
            <motion.div key={currentSlide} {...canvasAnimation}>
              <ProjectCanvas
                getCurrentVideoLength={getCurrentVideoLength}
                currentProject={currentProject}
                inView={inView}
              />
            </motion.div>
          ) : null}
        </ProjectsContainer>
      </Container>
    </div>
  )
}

export default memo(Projects)
