import { useCallback, useMemo, useRef } from 'react'
import { useQuery } from '@baseapp-frontend/core'

import Loading from './Loading'
import Container from 'components/Container'
import { VerticalsContainer, VerticalsTitle } from './styled'

import VerticalsApi from 'api/VerticalsApi'
import { CarouselProvider } from 'components/TimedCarousel/context'
import { VerticalsCarousel } from './VerticalsCarousel'

const CAROUSEL_DELAY = 10000 // ms

export default function Verticals() {
  const containerRef = useRef<HTMLDivElement>(null)

  const { data, isFetching } = useQuery('verticals', () => VerticalsApi.getVerticals(), {
    cacheTime: 0,
  })
  const verticals = useMemo(() => data?.results || [], [data])

  const carouselDelay = useCallback(() => CAROUSEL_DELAY, [])

  return (
    <CarouselProvider slideCount={verticals.length} delayForSlide={carouselDelay}>
      <Container ref={containerRef}>
        <VerticalsContainer>
          {isFetching || !verticals.length ? (
            <Loading />
          ) : (
            <>
              <VerticalsTitle component="h2">Verticals</VerticalsTitle>
              <VerticalsCarousel containerRef={containerRef} verticals={verticals} />
            </>
          )}
        </VerticalsContainer>
      </Container>
    </CarouselProvider>
  )
}
