import { FC } from 'react'
import Image from 'next/image'
import { Box, Typography } from '@mui/material'

import ClientInfo from '../ClientInfo'

import { TestimonialHead } from '../Testimonial/styled'
import { PlayCircleButton, WatchVideoContainer, WatchVideoTypography } from './styled'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'

const Content: FC<IContent> = ({ testimonial, handleOpenModal }) => {
  return (
    <Box component="div" width="100%">
      <TestimonialHead>
        {testimonial.companyLogo && (
          <Image
            loader={({ src, width }) => `${src}?w=${width}`}
            src={testimonial.companyLogo.fullSize}
            alt={testimonial.company ? testimonial.company : 'company logo'}
            objectFit="contain"
            width={185}
            height={50}
            objectPosition="left"
          />
        )}
      </TestimonialHead>

      <Typography variant="h6" component="p" sx={{ marginBottom: '1.5rem' }}>
        “{testimonial.text}”
      </Typography>

      <ClientInfo testimonial={testimonial} />

      {(testimonial.videoFile || testimonial.embedVideoUrl) && (
        <WatchVideoContainer onClick={handleOpenModal}>
          <PlayCircleButton>
            <PlayArrowRoundedIcon />
          </PlayCircleButton>
          <WatchVideoTypography>Watch Testimonial</WatchVideoTypography>
        </WatchVideoContainer>
      )}
    </Box>
  )
}

export default Content
