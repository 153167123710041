import { StyledIconButton } from 'components/TimedCarousel/Controls/styled'
import { FC } from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useCarouselState } from 'components/TimedCarousel/context'

const Buttons: FC<IButtonsProps> = ({ leftSide }) => {
  const { leftDisabled, rightDisabled, prevSlide, nextSlide } = useCarouselState()

  return (
    <>
      {leftSide ? (
        <StyledIconButton
          aria-label="previous-slide"
          size="large"
          onClick={prevSlide}
          disabled={leftDisabled}
          title="Previous Slide"
        >
          <KeyboardArrowLeftIcon />
        </StyledIconButton>
      ) : (
        <StyledIconButton
          aria-label="next-slide"
          size="large"
          onClick={nextSlide}
          disabled={rightDisabled}
          title="Next Slide"
        >
          <KeyboardArrowRightIcon />
        </StyledIconButton>
      )}
    </>
  )
}

export default Buttons
