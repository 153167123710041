import { Box, styled } from '@mui/material'

export const ClientInfoContainer = styled(Box)(({ theme }) => ({
  display: 'block',
}))

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
}))
