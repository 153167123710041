import Carousel from 'components/TimedCarousel'
import { useCarouselState } from 'components/TimedCarousel/context'
import { useInView } from 'framer-motion'
import { useEffect, useRef } from 'react'
import Testimonial from './Testimonial'

const TestimonialsCarousel = ({ testimonials }: { testimonials: ITestimonialsResult[] }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const { currentSlide, pauseTimer, resumeTimer } = useCarouselState()

  const inView = useInView(containerRef, { amount: 0.5 })

  useEffect(() => {
    if (inView) {
      resumeTimer()
    } else {
      pauseTimer()
    }
  }, [inView, pauseTimer, resumeTimer])

  return (
    <div ref={containerRef}>
      <Carousel index={currentSlide}>
        {testimonials.map((testimonial, index) => (
          <Testimonial key={index} index={index} inView={inView} testimonial={testimonial} />
        ))}
      </Carousel>
    </div>
  )
}

export default TestimonialsCarousel
