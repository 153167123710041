import { MouseEvent, useCallback } from 'react'
import { IndicatorsContainer } from './styled'
import Indicator from './Indicator'

export default function Indicators({
  count,
  currentSlide,
  getCurrentTimePercentage,
  setSlide = () => null,
  barcolor,
  backgroundColor,
  inView,
}: IIndicatorsProps) {
  const onClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const index = Number(event?.currentTarget.getAttribute('data-index'))
      setSlide(index)
    },
    [setSlide],
  )

  return (
    <IndicatorsContainer>
      {[...Array(count)].map((_, index) => (
        <Indicator
          key={index}
          index={index}
          inView={inView}
          getCurrentTimePercentage={getCurrentTimePercentage}
          currentSlide={currentSlide}
          onClick={onClick}
          barcolor={barcolor}
          backgroundColor={backgroundColor}
        />
      ))}
    </IndicatorsContainer>
  )
}
