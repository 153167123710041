import { Box, Typography, styled } from '@mui/material'
import { IconButton } from '@mui/material'

export const WatchVideoTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.primary[500],
  textDecoration: 'underline',
}))

export const PlayCircleButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary[500],
  color: theme.palette.surface.light,
  width: 34,
  height: 34,
  '&:hover': {
    backgroundColor: theme.palette.primary[500],
  },
}))

export const WatchVideoContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'inline-flex',
  cursor: 'pointer',
  gap: theme.spacing(1.25),
  alignItems: 'center',
}))
