import { useCallback, useEffect, useRef, useState } from 'react'
import { IndicatorBar, IndicatorContainer } from './styled'

const Indicator = ({
  index,
  currentSlide,
  getCurrentTimePercentage,
  barcolor,
  backgroundColor,
  inView,
  ...props
}: IIndicatorProps) => {
  const [fill, setFill] = useState(
    index < currentSlide ? 100 : index === currentSlide ? getCurrentTimePercentage() : 0,
  )

  const animationRef = useRef<number | null>(null)
  const isActive = useRef(false)

  const updateFill = useCallback(() => {
    if (!isActive.current) return

    setFill(getCurrentTimePercentage())

    animationRef.current = requestAnimationFrame(updateFill)
  }, [getCurrentTimePercentage])

  useEffect(() => {
    if (index < currentSlide) {
      setFill(100)
      return
    }

    if (index > currentSlide) {
      setFill(0)
      return
    }

    if (inView && index === currentSlide) {
      if (!isActive.current) {
        isActive.current = true
        animationRef.current = requestAnimationFrame(updateFill)
      }
    } else {
      isActive.current = false
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
        animationRef.current = null
      }
    }

    return () => {
      isActive.current = false
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
        animationRef.current = null
      }
    }
  }, [index, inView, currentSlide, updateFill])

  return (
    <IndicatorContainer data-index={index} backgroundColor={backgroundColor} {...props}>
      <IndicatorBar
        sx={{
          width: `${fill}%`,
          backgroundColor: barcolor ?? 'primary.500',
        }}
      />
    </IndicatorContainer>
  )
}

export default Indicator
