import { Modal, Backdrop } from '@mui/material'
import { ModalCloseButton, VideoContainer } from './styled'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useRef } from 'react'

const VideoModal = ({ isModalOpen, handleCloseModal, testimonial }: IModalVideoProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const getEmbedUrlWithAutoplay = (url: string) => {
    return url.includes('?') ? `${url}&autoplay=1` : `${url}?autoplay=1`
  }

  const closeModal = () => {
    handleCloseModal(videoRef)
  }

  const embedVideo = testimonial.embedVideoUrl && !testimonial.videoFile
  const videoFile = testimonial.videoFile

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      disableScrollLock
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
        },
      }}
    >
      <>
        <ModalCloseButton onClick={closeModal}>
          <CloseRoundedIcon />
        </ModalCloseButton>
        <VideoContainer>
          {isModalOpen && videoFile && (
            <video width="100%" height="100%" controls autoPlay ref={videoRef}>
              <source src={testimonial.videoFile} type="video/mp4" />
              <source src={testimonial.videoFile} type="video/webm" />
              Your browser does not support the video formats available.
            </video>
          )}
          {isModalOpen && embedVideo && (
            <iframe
              width="100%"
              height="100%"
              src={getEmbedUrlWithAutoplay(testimonial.embedVideoUrl)}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
            ></iframe>
          )}
        </VideoContainer>
      </>
    </Modal>
  )
}

export default VideoModal
