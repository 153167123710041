import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import {
  AnimationHandler,
  AnimationHandlerResponse,
} from 'react-responsive-carousel/lib/ts/components/Carousel/types'
import { memo } from 'react'
import { ITimedCarouselProps } from './types'
import { StyledCarousel } from './styled'

const fadeAnimationHandler: AnimationHandler = (props, state): AnimationHandlerResponse => {
  const transitionTime = props.transitionTime + 'ms'
  const transitionTimingFunction = 'ease-in-out'

  let slideStyle: React.CSSProperties = {
    position: 'absolute',
    display: 'block',
    zIndex: -2,
    minHeight: '100%',
    opacity: 0,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    transitionTimingFunction: transitionTimingFunction,
    msTransitionTimingFunction: transitionTimingFunction,
    MozTransitionTimingFunction: transitionTimingFunction,
    WebkitTransitionTimingFunction: transitionTimingFunction,
    OTransitionTimingFunction: transitionTimingFunction,
  }

  if (!state.swiping) {
    slideStyle = {
      ...slideStyle,
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime,
    }
  }

  return {
    slideStyle,
    selectedStyle: { ...slideStyle, zIndex: 1, opacity: 1, position: 'relative' },
    prevStyle: { ...slideStyle },
  }
}

function Carousel({ children, index, ...props }: ITimedCarouselProps) {
  return (
    <StyledCarousel
      autoPlay={false}
      showArrows={false}
      swipeable={false}
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      selectedItem={index}
      infiniteLoop={false}
      animationHandler={fadeAnimationHandler}
      {...props}
    >
      {children}
    </StyledCarousel>
  )
}

export default memo(Carousel)
