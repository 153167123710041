/* eslint react/no-unknown-property: 0 */

import { Suspense, useRef } from 'react'
import { Html, Loader } from '@react-three/drei'
import { useTheme } from '@mui/material'

import Laptop from './Laptop'
import { Scene } from 'components/3DScene'
import { Canvas } from 'components/3DScene'

import { LaptopContainer } from './styled'
import { useInView } from 'framer-motion'

const HeroCanvas = () => {
  const containerRef = useRef<HTMLDivElement>(null)

  const theme = useTheme()
  const inView = useInView(containerRef, { amount: 0.5 })

  return (
    <LaptopContainer ref={containerRef}>
      <Canvas canvasProps={{ dpr: 1, frameloop: 'never' }}>
        <spotLight position={[0, 10, 40]} intensity={0.4} />
        <Scene
          cameraProps={{ position: [0, 1, 30], near: 0.1, far: 50, zoom: 2 }}
          spotlightProps={{ position: [0, 20, 9], intensity: 6 }}
        />
        <Suspense
          fallback={
            <Html>
              <Loader
                barStyles={{ background: theme.palette.primary.main }}
                dataInterpolation={(p) => `Loading ${p.toFixed(0)}%`}
                containerStyles={{ background: theme.palette.surface.A900 }}
              />
            </Html>
          }
        >
          <Laptop inView={inView} />
        </Suspense>
      </Canvas>
    </LaptopContainer>
  )
}

export default HeroCanvas
