import { createContext, useContext, useMemo } from 'react'
import useTimedCarousel from './hooks/useTimedCarousel'

interface CarouselContextType {
  currentSlide: number
  slideCount: number
  nextSlide: () => void
  prevSlide: () => void
  setSlide: (slide: number) => void
  leftDisabled: boolean
  rightDisabled: boolean
  getCurrentTimePercentage: () => number
  pauseTimer: () => void
  resumeTimer: () => void
}

export const CarouselContext = createContext<CarouselContextType | null>(null)

export const useCarouselState = () => {
  const context = useContext(CarouselContext)
  if (!context) {
    throw new Error('useCarouselState must be used within a CarouselProvider')
  }
  return context
}

export const CarouselProvider = ({
  children,
  slideCount,
  delayForSlide,
}: {
  children: React.ReactNode
  slideCount: number
  delayForSlide: (slide: number) => number
}) => {
  const {
    currentSlide,
    nextSlide,
    prevSlide,
    setSlide,
    getCurrentTimePercentage,
    pauseTimer,
    resumeTimer,
  } = useTimedCarousel(slideCount, delayForSlide)

  const leftDisabled = useMemo(() => currentSlide === 0, [currentSlide])
  const rightDisabled = useMemo(() => currentSlide === slideCount - 1, [currentSlide, slideCount])

  const providerValue = useMemo(
    () => ({
      currentSlide,
      slideCount,
      nextSlide,
      prevSlide,
      setSlide,
      leftDisabled,
      rightDisabled,
      getCurrentTimePercentage,
      pauseTimer,
      resumeTimer,
    }),
    [
      currentSlide,
      slideCount,
      nextSlide,
      prevSlide,
      setSlide,
      leftDisabled,
      rightDisabled,
      getCurrentTimePercentage,
      pauseTimer,
      resumeTimer,
    ],
  )

  return <CarouselContext.Provider value={providerValue}>{children}</CarouselContext.Provider>
}
