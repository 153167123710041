import { Box, IconButton, styled } from '@mui/material'

export const ModalCloseButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.surface[100],
  color: theme.palette.surface[900],
  width: 42,
  height: 42,
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
  '&:hover': {
    backgroundColor: theme.palette.surface[100],
  },
}))

export const VideoContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '350px',
  height: '185px',
  borderRadius: '12px',
  overflow: 'hidden',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: theme.palette.surface.A900,
  [theme.breakpoints.up('sm')]: {
    height: '360px',
    maxWidth: '640px',
  },
}))
