import Indicator from '../../TimedCarousel/Indicators/Indicator'
import { VerticalsIndicatorContainer, Title } from './styled'

export default function VerticalsIndicators({
  verticals,
  inView,
  currentSlide,
  setSlide,
  getCurrentTimePercentage,
}: IVerticalsIndicatorsProps) {
  return (
    <>
      {verticals?.map((vertical, index) => (
        <VerticalsIndicatorContainer onClick={() => setSlide(index)} key={vertical.slug}>
          <Title variant="h6" component="p">
            {vertical.title}
          </Title>
          <Indicator
            key={index}
            index={index}
            inView={inView}
            currentSlide={currentSlide}
            getCurrentTimePercentage={getCurrentTimePercentage}
            style={{ flexGrow: 0 }}
          />
        </VerticalsIndicatorContainer>
      ))}
    </>
  )
}
