import { styled, Box, Typography } from '@mui/material'

export const VerticalsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.surface['A900'],
  padding: theme.spacing(3, 3),
}))

export const LinearGradientBorderWrapper = styled(Box)(({ theme }) => ({
  background: `linear-gradient(90deg, rgba(25, 63, 132, 1) 50%, rgba(255, 255, 255, 1) 100%)`,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(0.2),
}))

export const VerticalsLinearGradientContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  background: `linear-gradient(90deg, rgba(0, 156, 216, 1) 50%, rgba(56, 116, 222, 1) 100%)`,
  display: 'grid',
  overflow: 'hidden',
  [theme.breakpoints.up(800)]: {
    gridTemplateColumns: '1.5fr 1fr',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '2fr 1fr',
  },
}))

export const ImageGradientContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  position: 'relative',
  height: '280px',
  [theme.breakpoints.up('sm')]: {
    height: '361px',
  },
  [theme.breakpoints.up(800)]: {
    height: '100%',
  },
}))

export const VerticalsContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    paddingBottom: theme.spacing(1),
  },
}))

export const CTAContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(4),
  gap: theme.spacing(1),
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}))

export const VerticalsDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  fontWeight: 400,
  minHeight: '400px',
  [theme.breakpoints.up('sm')]: {
    minHeight: '210px',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '230px',
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: '150px',
  },
})) as typeof Typography
